import { ElMessageBox, ElMessage } from 'element-plus';
import { apiEgpmTaskDel } from '@/request/egpmApi';
import withLoading from '@/utils/loading';

export default (getTableData) => {

    function onDelete(row) {
        ElMessageBox.confirm(`确定删除任务“${row.taskName}”`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
        }).then(() => {
            deleteTask(row.id)
        })
    }

    // 发送删除请求
    async function deleteTask(id) {
        const { code } = await withLoading(apiEgpmTaskDel)({ id });
        if (code !== '0') return;
        ElMessage.success('删除成功');
        getTableData();
    }

    return { onDelete }
}